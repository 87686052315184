import React from "react";

type Props = {
  size?: number;
};

export default function Acd({ size = 40 }: Props) {
  const styleSt0 = {
    fill: "#000858",
  };
  const styleSt1 = {
    fill: "#00A1E0",
  };

  return (
    <svg
      className="icon"
      version="1.1"
      id="Calque_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 100 100"
      width={size + "px"}
    >
      <g id="tiime-integrations-acd">
        <g id="Group-9" transform="translate(14.000000, 25.000000)">
          <path
            id="Fill-1"
            style={styleSt0}
            d="M50.5,29.5c-1.7,7.4-8.5,12.9-16.4,13.1c-1.3,0-2.6-0.1-3.8-0.3c-3.2-0.6-6.1-2.1-8.5-4.4
           c-3.4-3.1-5.3-7.5-5.5-12c-0.2-4.5,1.6-9,4.8-12.3c3.2-3.3,7.5-5.3,12.1-5.4c7.4-0.2,14.1,4.2,16.7,10.9l0.3,0.6L44.5,20
           c-0.8,0-1.6-0.3-2.1-1c-2.1-2.8-5.5-4.5-9.1-4.3c-6.1,0.2-10.9,5.2-10.7,11.2c0.2,6,5.2,10.7,11.3,10.6c4-0.1,7.6-2.3,9.3-5.6
           c0.3-0.6,1-1,1.6-1L50.5,29.5L50.5,29.5z"
          />
          <path
            id="Fill-3"
            style={styleSt0}
            d="M17.9,41.7h-5.4c-0.7,0-1.4-0.5-1.7-1.2l-2.6-7.1l-8.7,1.9l2.4-6.8l4.1-0.9l-3.7-10
           c-0.1-0.2-0.3-0.2-0.3-0.2c-0.1,0-0.3,0-0.3,0.2l-8.6,22.9c-0.3,0.7-0.9,1.2-1.7,1.2H-14L-1.6,9.9c0.3-0.8,1.1-1.3,1.9-1.3h3.6
           c0.9,0,1.6,0.5,1.9,1.3L17.9,41.7z"
          />
          <path
            id="Fill-5"
            style={styleSt0}
            d="M68.2,8.6h-12c-1.1,0-1.9,0.9-1.9,1.9v21.7h5.6c0.4,0,0.8-0.3,0.8-0.8V15.5
           c0-0.6,0.5-1.1,1.1-1.1H68c7.1,0,11.7,4.2,11.7,10.7S75.1,35.9,68,35.9h-2.5c-0.6,0-1.1,0.5-1.1,1.1v4.8h3.7
           c10.5,0,17.8-6.8,17.8-16.6S78.7,8.6,68.2,8.6"
          />
          <path
            id="Fill-7"
            style={styleSt1}
            d="M60.8,35.9v5.4c0,0.2-0.1,0.3-0.2,0.4c-0.1,0.1-0.2,0.1-0.3,0.1h-5.4c-0.1,0-0.2,0-0.3-0.1
           c-0.1-0.1-0.2-0.3-0.2-0.4v-5.4c0-0.3,0.2-0.6,0.6-0.6h5.4c0.2,0,0.3,0.1,0.4,0.2C60.7,35.6,60.8,35.7,60.8,35.9L60.8,35.9
           L60.8,35.9z"
          />
        </g>
      </g>
    </svg>
  );
}
